//inputs

// Modals

import * as Portal from "@radix-ui/react-portal";
import type React from "react";

export { ZipModal } from "./modals/ZipModal.tsx";

export { ErrorMessage } from "./inputs/common/ErrorMessage.tsx";

// RFH

//buttons
export { ZipButton, ZipButton as Button } from "./buttons/ZipButton.tsx";
export { ZDButton } from "./buttons/index.tsx";
export { GroupedButtons } from "./buttons/GroupedButtons.tsx";

//spinners
export { Spinner } from "./spinners/Spinners.tsx";
export { RotatingArrows } from "./spinners/RotatingArrows.tsx";
export { ThreeDotsPulse } from "./spinners/ThreeDotPulse.tsx";

// normalize
export { default as normalizers } from "./utilities/normalize.ts";
export {
	normalizeVin,
	normalizePhone,
	normalizePath,
	normalizeNumericEntry,
	normalizeNumbers,
	normalizeDealNumbers,
	normalizeDate,
	normalizePriceEntry,
	normalizeDateImprovedWithPlaceholders,
	normalizeDateDash,
} from "./utilities/normalize.ts";

//misc
export { ZipDealText, ZipDealTextSpan } from "./misc/ZipDealText.tsx";

//hooks
export { useErrorScrolling } from "./hooks/useErrorScrolling.tsx";
export { usePageState } from "./hooks/usePageState.tsx";
export { useConsoleLogFormValues } from "./hooks/useConsoleLogFormValues.tsx";
export { useZipMultiInput } from "./hooks/useZipMultiInput.tsx";
export { useValidatedForm } from "./hooks/useValidatedForm.tsx";
export { useValidatedZodForm } from "./hooks/useValidatedZodForm.tsx";
export { useChoicesTranslation } from "./hooks/useChoicesTranslation.tsx";
export { useFilterField } from "./hooks/useFilterField.tsx";
export { useWhyDidYouUpdate } from "./hooks/useWhyDidYouUpdate.ts";
export { useZipSearchParam2 } from "./hooks/useZipSearchParam2.tsx";
export { useZipSearchParam } from "./hooks/useZipSearchParam.tsx";
export { usePinned } from "./hooks/usePinned.tsx";

//utilities
export { flattenKeys } from "./utilities/flattenKeys.ts";
export { flattenObj } from "./utilities/flattenObj.ts";
export { makeArray } from "./utilities/makeArray.ts";
export { scrollToHeader } from "./utilities/utils.ts";
export { getBackgroundColor } from "./utilities/getBackgroundColor.ts";

//images
export { ImageWithFallback } from "./images/ImageWithFallback.tsx";

// icons
export { BoldCheckmarkIcon } from "./icons/field/BoldCheckmarkIcon.tsx";
export { CCIcon } from "./icons/field/CCIcon.tsx";
export { RewindIcon } from "./icons/field/RewindIcon.tsx";
export { CheckIcon } from "./icons/field/CheckIcon.tsx";
export { PauseIcon } from "./icons/field/PauseIcon.tsx";
export { ThreeDotsIcon } from "./icons/field/ThreeDotsIcon.tsx";
export { SearchIcon } from "./icons/field/SearchIcon.tsx";
export { UncheckIcon } from "./icons/field/UncheckIcon.tsx";
export { RefreshIcon } from "./icons/field/RefreshIcon.tsx";
export { DownArrowIcon } from "./icons/field/DownArrowIcon.tsx";
export { EmptyFieldIcon } from "./icons/field/EmptyFieldIcon.tsx";
export { HelpIcon } from "./icons/field/HelpIcon.tsx";
export { ExportIcon } from "./icons/field/ExportIcon.tsx";
export { CloseIcon } from "./icons/field/CloseIcon.tsx";
export { PlayIcon } from "./icons/field/PlayIcon.tsx";
export { PoweredByZipDealIcon } from "./icons/zipdeal/PoweredByZipDealIcon.tsx";
export { EmailIcon } from "./icons/zipdeal/EmailIcon.tsx";
export { HamburgerIcon } from "./icons/HamburgerIcon.tsx";
export { ZipIconVerifiedUser } from "./icons/ZipIcons.tsx";

export {
	DealershipSettingsIcon,
	ZiplogsIcon,
	DealershipReportingIcon,
	MetricsIcon,
	DashIcon,
} from "./icons/field/SidebarIcons.tsx";

export { RotatingRefreshIcon } from "./icons/RotatingRefreshIcon.tsx";

export {
	PrinterIcon,
	VerifiedIcon,
	VerifiedIconOutline,
	AddShieldOutline,
} from "./icons/ButtonIcons.tsx";

// containers
export {
	Dashboard,
	useDashboardContext,
	useDashboard,
} from "./layouts/Dashboard.tsx";
export { BetterDashboard } from "./layouts/BetterDashboard.tsx";
export { BestDashboard } from "./layouts/BestDashboard.tsx";
export { ZipDashboard } from "./layouts/ZipDashboard.tsx";
export { SidebarHeader } from "./layouts/SidebarHeader.tsx";
export { SidebarLink } from "./layouts/SidebarLink.tsx";
export { TileContainer } from "./layouts/TileContainer.tsx";

// formik wrapper

export {
	BrandContext,
	BrandProvider,
	useBrand,
} from "./contexts-and-providers/BrandProvider.tsx";

export {
	usePagination,
	PaginationProvider,
} from "./contexts-and-providers/PaginationProvider.tsx";
export {
	ContentSizeProvider,
	useContentSize,
	usePortalsRefElement,
} from "./contexts-and-providers/ContentSizeProvider.tsx";

export {
	emptyHandler,
	emptyNormalizeFn,
	disabledHandler,
	createId,
	translatableTrueFalseData,
	translatableYesNoData,
	emptyArray,
	emptyObj,
	yesNoData,
	trueFalseData,
} from "./utilities/stables.ts";

export { Compose } from "./utilities/Compose.tsx";

export {
	DroppableContainer,
	DraggableItem,
	SortableItem,
	SortableItemProvider,
	DndCloneProvider,
	useDndClone,
	useContainer,
	useTreeContainer,
	SortableTreeItem,
	TreeItem,
	useDndTree,
	DndTreeProvider,
	useDndTreeBags,
	useDndCloneBags,
} from "./contexts-and-providers/dnd/index.tsx";

export {
	ZipClientsFilterProvider,
	useZipClientsFilter,
	DateTimeFilterProvider,
	OrderingFilterProvider,
	useYearsFilter,
	useTrimFilter,
	useYearFilter,
	useOrderingFilter,
	useModelFilter,
	useModelReleaseFilter,
	useMakeFilter,
	useClientFilter,
	useDateTimeFilter,
	useIdFilter,
	SearchParamsKeeperProvider,
	useSearchParamsKeeper,
} from "./query-filters/index.tsx";

export { ActionsComponent } from "./components/ActionsComponent.tsx";
export { ValidatedFormComponent } from "./components/ValidatedFormComponent.tsx";
export { LanguageSwitcherComponent } from "./components/LanguageSwitcherComponent.tsx";
export { Tooltip } from "./components/Tooltip.tsx";
export { ZipErrorBoundary } from "./components/ZipErrorBoundary.tsx";
export { ZipTagChip } from "./components/ZipTagChip.tsx";
// Aliases
import { ClientOnly } from "remix-utils/client-only";

export const ZipPortal = ({
	children,
	className = "",
	container = undefined,
}: {
	children: React.ReactNode;
	className?: string;
	container?: HTMLElement | null;
}) => {
	return (
		<ClientOnly fallback={<div />}>
			{() => (
				<Portal.Root container={container} className={className}>
					{children}
				</Portal.Root>
			)}
		</ClientOnly>
	);
};

// Non-RHF
export { ZipTextInput } from "./inputs/zip/ZipTextInput.tsx";
export { ZipTextNumberInput } from "./inputs/zip/ZipTextNumberInput.tsx";
export { ZipSwitchInput } from "./inputs/zip/ZipSwitchInput.tsx";
export { ZipHiddenInput } from "./inputs/zip/ZipHiddenInput.tsx";
export { ZipArrayHiddenInputs } from "./inputs/zip/ZipArrayHiddenInputs.tsx";
export { ZipHiddenCheckboxInput } from "./inputs/zip/ZipHiddenCheckboxInput.tsx";
export { ZipTextDateInput } from "./inputs/zip/ZipTextDateInput.tsx";
export { ZipSelectInput } from "./inputs/zip/ZipSelectInput.tsx";
export { ZipSelectCmdKInput } from "./inputs/zip/ZipSelectCmdKInput.tsx";
export { ZipSelectRadixUIInput } from "./inputs/zip/ZipSelectRadixUIInput.tsx";
export { ZipSelectField as ZipSelectField2 } from "./inputs/zip/ZipSelectField.tsx";
export { ZipComboBoxInput } from "./inputs/zip/ZipComboBoxInput.tsx";
export { ZipCheckboxInput } from "./inputs/zip/ZipCheckboxInput.tsx";
export { ZipCheckbox2Input } from "./inputs/zip/ZipCheckbox2Input.tsx";
export { ZipMultiCheckboxInput } from "./inputs/zip/ZipMultiCheckboxInput.tsx";
export { ZipSearchStatelyInput } from "./inputs/zip/ZipSearchStatelyInput.tsx";
export { ZipSelectionInput } from "./inputs/zip/ZipSelectionInput.tsx";
export { ZipTagsInput, useZipTagsInput } from "./inputs/zip/ZipTagsInput.tsx";
export {
	ZipDesignedRadioButtonsInput,
	ZipDesignedTrueFalseRadioButtonsInput,
} from "./inputs/zip/ZipDesignedRadioButtonsInput.tsx";
export {
	ZipRadioButtonsInput,
	ZipTrueFalseRadioButtonsInput,
} from "./inputs/zip/ZipRadioButtonsInput.tsx";
export { ZipMentionsTextInput } from "./inputs/zip/ZipMentionsTextInput.tsx";
export { ZipPlateMentionsProvider } from "./inputs/zip/components/ZipPlateMentionsProvider.tsx";
export { ZipProgressBar } from "./inputs/zip/ZipProgressBar.tsx";

// Formless
export { FormlessDesignedRadioButtonsInput } from "./inputs/formless/FormlessDesignedRadioButtonsInput.tsx";
export { FormlessSelectInput } from "./inputs/formless/FormlessSelectInput.tsx";
export { FormlessDatepickerInput } from "./inputs/formless/FormlessDatepickerInput.tsx";
export { FormlessSwitchInput } from "./inputs/formless/FormlessSwitchInput.tsx";
export { FormlessNonsearchSelectInput } from "./inputs/formless/FormlessNonsearchSelectInput.tsx";
export { FormlessRadioButtonsInput } from "./inputs/formless/FormlessRadioButtonsInput.tsx";
export { FormlessComboBoxInput } from "./inputs/formless/FormlessComboBoxInput.tsx";
export { FormlessTextInput } from "./inputs/formless/FormlessTextInput.tsx";
export { FormlessMultiCheckboxInput } from "./inputs/formless/FormlessMultiCheckboxInput.tsx";
export {
	FormlessTagsInput,
	useFormlessTagsInput,
} from "./inputs/formless/FormlessTagsInput.tsx";
export { ZipFormlessAdvancedSelectInput } from "./inputs/formless/ZipFormlessAdvancedSelectInput.tsx";
export { FormlessSelectRadixUIInput } from "./inputs/formless/FormlessSelectRadixUIInput.tsx";

// Tremor
export { TremorCard } from "./tremor/TremorCard.tsx";
export { TremorText } from "./tremor/TremorText.tsx";
export { TremorFlex } from "./tremor/TremorFlex.tsx";
export { TremorIcon } from "./tremor/TremorIcon.tsx";
export { TremorMetric } from "./tremor/TremorMetric.tsx";
export { TremorGrid } from "./tremor/TremorGrid.tsx";
export { TremorBadge } from "./tremor/TremorBadge.tsx";
export { TremorBarChart } from "./tremor/TremorBarChart.tsx";
export { TremorTitle } from "./tremor/TremorTitle.tsx";
export { TremorDonutChart } from "./tremor/TremorDonutChart.tsx";
export { TremorBarList } from "./tremor/TremorBarList.tsx";
export { TremorTab } from "./tremor/TremorTab.tsx";
export { TremorTabGroup } from "./tremor/TremorTabGroup.tsx";
export { TremorTabList } from "./tremor/TremorTabList.tsx";
export { TremorTabPanels } from "./tremor/TremorTabPanels.tsx";
export { TremorTabPanel } from "./tremor/TremorTabPanel.tsx";
export { TremorList } from "./tremor/TremorList.tsx";
export { TremorListItem } from "./tremor/TremorListItem.tsx";
export { TremorAreaChart } from "./tremor/TremorAreaChart.tsx";

// Machines

// Stores
export { useZipTipStore } from "./stores/zipTipsStore.ts";

// Animations
export { PopHover } from "./animations/PopHover.tsx";
export { TriggerAnimation } from "./animations/TriggerAnimation.tsx";
export { default as animations } from "./animations/animations.ts";

// Portals
export {
	createHtmlPortalNode,
	createSvgPortalNode,
	InPortal,
	OutPortal,
} from "./utilities/Portals.tsx";

export { useToast, ToastProvider } from "./hooks/ZipToast.tsx";
export { ZipMentionsEditor } from "./plate-ui/ZipMentionsEditor.tsx";
